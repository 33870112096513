<template>
  <div>
    <button
      class="button is-small is-primary mb-4"
      title="Add Bank Account"
      @click="openModal"
    >
      Add Bank Account
    </button>

    <div
      class="modal"
      :class="{'is-active': isModalOpen}"
    >
      <div
        class="modal-background"
        @click="closeModal"
      />

      <div class="modal-content">
        <div class="box">
          <div class="is-flex is-flex-wrap-wrap columns">
            <div class="field is-full column">
              <label class="label">Bank</label>
              <div class="select is-fullwidth">
                <select v-model="newBankAccount.bankId">
                  <option
                    v-for="bank in banks"
                    :key="bank.bankId"
                    :value="bank.bankId"
                  >
                    {{ bank.name }} / {{ bank.code }}
                  </option>
                </select>
              </div>
            </div>

            <div class="field is-half column">
              <label class="label">Account Number</label>
              <div class="control">
                <input
                  v-model="newBankAccount.accountNumber"
                  class="input"
                  type="text"
                  placeholder="Enter account number"
                >
              </div>
            </div>

            <div class="field is-half column">
              <label class="label">Company Name</label>
              <div class="control">
                <input
                  v-model="newBankAccount.companyName"
                  class="input"
                  type="text"
                  placeholder="Enter company name"
                >
              </div>
            </div>

            <div class="field is-half column">
              <label class="label">Branch Name</label>
              <div class="control">
                <input
                  v-model="newBankAccount.branchName"
                  class="input"
                  type="text"
                  placeholder="Enter branch name"
                >
              </div>
            </div>

            <div class="field is-half column">
              <label class="label">Branch Code</label>
              <div class="control">
                <input
                  v-model="newBankAccount.branchCode"
                  class="input"
                  type="text"
                  placeholder="Enter branch code"
                >

                <p
                  v-if="errors.branchCode"
                  class="help is-danger"
                >
                  {{ errors.branchCode }}
                </p>
              </div>
            </div>

            <div class="field is-grouped is-grouped-right column">
              <p class="control">
                <button
                  class="button is-primary"
                  :class="{ 'is-loading': isLoading }"
                  :disabled="isSaveButtonActive()"
                  @click="addBankAccount"
                >
                  Save
                </button>
              </p>
              <p class="control">
                <a
                  class="button is-light"
                  @click="closeModal"
                >
                  Cancel
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

export default {
    props: {
        banks: {
            type: Object,
            default: null,
        },
    },

    emits: ['bankAccountAdded'],

    async setup(_props, { emit }) {
        const {
            postBankAccount,
        } = inject('bank_accounts');

        const newBankAccount = ref({});
        const isLoading = ref(false);
        const isModalOpen = ref(false);
        const errors = ref({});

        const closeModal = () => {
            newBankAccount.value.accountNumber = '';
            newBankAccount.value.companyName = '';
            newBankAccount.value.branchName = '';
            newBankAccount.value.branchCode = '';
            newBankAccount.value.bankId = '';
            isModalOpen.value = false;
        };

        const openModal = () => {
            isModalOpen.value = true;
            errors.value = {};
        };

        const isSaveButtonActive = () => {
            const requiredFields = [
                'bankId',
                'accountNumber',
                'companyName',
                'branchName',
                'branchCode',
            ];
            return !requiredFields.every((keyName) => (
                newBankAccount.value[keyName]
                && newBankAccount.value[keyName].trim()
            ));
        };

        const validate = () => {
            if (newBankAccount.value.branchCode.length !== 3) {
                errors.value.branchCode = 'Branch Code should three characters.';
                return false;
            }

            return true;
        };

        const addBankAccount = async () => {
            if (validate()) {
                const addedBankAccount = await postBankAccount(newBankAccount.value);
                emit('bankAccountAdded', addedBankAccount);
                closeModal();
            }
        };

        return {
            addBankAccount,
            closeModal,
            errors,
            isModalOpen,
            isLoading,
            isSaveButtonActive,
            newBankAccount,
            openModal,
        };
    },
};
</script>
