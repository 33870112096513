/* eslint-disable camelcase */
import { ref, watch } from 'vue';
import { httpGet, httpPost, getCsv } from '../http';

const PATH = 'transactions/';

export default (filters, ordering) => {
    const transactions = ref([]);

    const snakeToCamel = (res) => res.map(({
        card_id: cardId,
        ip_address: ipAddress,
        merchant_id: merchantId,
        transaction_id: transactionId,
        timestamp,
        currency,
        target_currency: targetCurrency,
        amount,
        entered_amount: enteredAmount,
        email,
        status,
        processing_fee: processingFee,
        card_details: cardDetails,
        bank_name: bankName,
        bank_code: bankCode,
        mid,
        nonce,
        first_name: firstName,
        surname,
    }) => ({
        cardId,
        ipAddress,
        merchantId,
        transactionId,
        timestamp: new Date(timestamp),
        currency,
        targetCurrency,
        amount,
        enteredAmount,
        email,
        status,
        processingFee,
        cardDetails,
        bankName,
        bankCode,
        mid,
        nonce,
        firstName,
        surname,
    }));

    const camelToSnake = (({
        amountFrom: amount_from,
        amountTo: amount_to,
        enteredAmount: entered_amount,
        cardId: card_id,
        currency,
        targetCurrency: target_currency,
        email,
        ipAddress: ip_address,
        merchantId: merchant_id,
        status,
        timestampFrom: timestamp_from,
        timestampTo: timestamp_to,
        transactionId: transaction_id,
        processingFee: processing_fee,
        cardDetails: card_details,
        bankName: bank_name,
        bankCode: bank_code,
        mid,
        nonce,
        firstName: first_name,
        surname,
    }) => ({
        amount_from,
        amount_to,
        entered_amount,
        card_id,
        currency,
        target_currency,
        ip_address,
        email,
        merchant_id,
        status,
        timestamp_from: timestamp_from.toISO(),
        timestamp_to: timestamp_to.toISO(),
        transaction_id,
        processing_fee,
        card_details,
        bank_name,
        bank_code,
        mid,
        nonce,
        first_name,
        surname,
    }));

    const fetchTransactions = async () => {
        const response = await httpGet(
            PATH,
            {
                ...camelToSnake(filters),
                ordering: ordering.value,
            },
        );
        transactions.value = snakeToCamel(response);
    };

    const fetchTransactionsCsv = async () => {
        const response = await getCsv(
            PATH,
            {
                ...camelToSnake(filters),
                ordering: ordering.value,
            },
        );
        return response;
    };

    const postRefund = async (id) => {
        const response = await httpPost(`payment/refund/${id}/`);
        return response;
    };

    const markTransactionAsRefund = (id) => {
        transactions.value.find((el) => el.transactionId === id).status = 'REFUND';
    };

    watch(filters, () => {
        fetchTransactions();
    });

    watch(ordering, () => {
        fetchTransactions();
    });

    return {
        transactions,
        fetchTransactions,
        fetchTransactionsCsv,
        postRefund,
        markTransactionAsRefund,
    };
};
