import { reactive } from 'vue';
import { DateTime } from 'luxon';

const today = DateTime.fromObject({
    hours: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
    zone: 'UTC',
});

const tomorrow = DateTime.fromObject({
    hours: 23,
    minute: 59,
    second: 59,
    millisecond: 999,
    zone: 'UTC',
});

export default () => {
    const filters = reactive({
        amountFrom: '0',
        amountTo: '250000000',
        bankName: '',
        accountNumber: '',
        currency: '',
        email: '',
        merchantId: '',
        status: 'CREATED',
        receivedBy: '',
        dateFrom: today,
        dateTo: tomorrow,
        receiptDateFrom: null,
        receiptDateTo: null,
        transactionId: '',
        sourceBankName: '',
        creation: '',
        nonce: '',
        firstName: '',
        surname: '',
        authorizedAmountFrom: '',
        authorizedAmountTo: '',
    });

    return filters;
};
