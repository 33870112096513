<template>
  <Suspense>
    <NavBar />
  </Suspense>
  <div class="hero">
    <div class="section">
      <router-view />
    </div>
  </div>
</template>

<script>
import { provide } from 'vue';

import blockedIps from '@/blocked_ips';
import btbTransactions from '@/btb_transactions';
import transactions from '@/transactions';
import merchantLockout from '@/merchant_lockout';
import alternativeBankIntervals from '@/alternative_bank';
import merchants from '@/merchants';
import banks from '@/banks';
import bankAccounts from '@/bank_accounts';

import NavBar from '@/main/NavBar.vue';

export default {
    components: {
        NavBar,
    },

    setup() {
        provide('alternative_bank', alternativeBankIntervals());
        provide('blocked_ips', blockedIps());
        provide('btb_transactions', btbTransactions());
        provide('transactions', transactions());
        provide('merchant_lockout', merchantLockout());
        provide('merchants', merchants());
        provide('banks', banks());
        provide('bank_accounts', bankAccounts());
    },
};
</script>
