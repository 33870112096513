<template>
  <thead>
    <tr class="is-centered">
      <OrderingToggle
        field="transactionId"
      >
        Transaction ID
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayCardDetails"
        field="cardDetails"
      >
        Card Details
      </OrderingToggle>
      <OrderingToggle
        field="cardId"
      >
        Card ID
      </OrderingToggle>
      <OrderingToggle
        field="email"
      >
        Email
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayNames"
        field="firstName"
      >
        First Name
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayNames"
        field="surname"
      >
        Surname
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayNonce"
        field="nonce"
      >
        Nonce
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayBankDetails"
        field="bankName"
      >
        Bank Name
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayBankDetails"
        field="bankCode"
      >
        Bank Code
      </OrderingToggle>
      <OrderingToggle
        field="ipAddress"
      >
        IP Address
      </OrderingToggle>
      <OrderingToggle
        field="amount"
      >
        Settled Amount
      </OrderingToggle>
      <OrderingToggle
        field="merchantId"
      >
        Merchant ID
      </OrderingToggle>
      <OrderingToggle
        v-if="shouldDisplayMid"
        field="mid"
      >
        MID
      </OrderingToggle>
      <OrderingToggle
        field="timestamp"
      >
        Date
      </OrderingToggle>
      <OrderingToggle
        field="status"
      >
        Status
      </OrderingToggle>
      <OrderingToggle
        field="processingFee"
      >
        Processing Fee
      </OrderingToggle>
      <OrderingToggle
        field="targetCurrency"
      >
        Authorized Amount
      </OrderingToggle>
      <th>
        Refund
      </th>
      <th />
    </tr>
  </thead>
</template>

<script>
import OrderingToggle from './OrderingToggle.vue';
import initializeFlagsmith from '../flagsmith';

export default {
    name: 'OrderableTableHeader',

    components: {
        OrderingToggle,
    },

    async setup() {
        const flagsmith = await initializeFlagsmith();

        const shouldDisplayCardDetails = flagsmith.hasFeature('should_display_card_details_admin');
        const shouldDisplayBankDetails = flagsmith.hasFeature('check_bank');
        const shouldDisplayMid = flagsmith.hasFeature('admin_mid');
        const shouldDisplayNonce = flagsmith.hasFeature('admin_nonce');
        const shouldDisplayNames = flagsmith.hasFeature('admin_ccg_names');

        return {
            shouldDisplayCardDetails,
            shouldDisplayBankDetails,
            shouldDisplayMid,
            shouldDisplayNonce,
            shouldDisplayNames,
        };
    },
};
</script>
