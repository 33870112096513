<template>
  <div>
    <AddBankAccount
      :banks="banks"
      @bank-account-added="bankAccountAdded"
    />

    <table class="table is-size-7 is-vcentered is-hoverable is-fullwidth blocked-ip-address-list">
      <thead>
        <tr>
          <th>Account Number</th>
          <th>Bank Name</th>
          <th>Bank Code</th>
          <th>Company Name</th>
          <th>Branch name</th>
          <th>Branch code</th>
          <th />
        </tr>
      </thead>

      <tbody>
        <tr
          v-for="bankAccount in bankAccounts"
          :key="bankAccount.bankAccountId"
        >
          <td>{{ bankAccount.accountNumber }}</td>
          <td>{{ bankAccount.bank.name }}</td>
          <td>{{ bankAccount.bank.code }}</td>
          <td>{{ bankAccount.companyName }}</td>
          <td>{{ bankAccount.branchName }}</td>
          <td>{{ bankAccount.branchCode }}</td>
          <td>
            <EditBankAccount
              :bank-account="bankAccount"
              :banks="banks"
              @bank-account-edited="bankAccountEdited"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { inject, ref } from 'vue';

import AddBankAccount from './AddBankAccount.vue';
import EditBankAccount from './EditBankAccount.vue';

export default {
    components: {
        AddBankAccount,
        EditBankAccount,
    },

    async setup() {
        const bankAccounts = ref([]);

        const {
            fetchBankAccounts,
        } = inject('bank_accounts');

        const {
            fetchBanks,
        } = inject('banks');

        const banks = await fetchBanks();

        bankAccounts.value = await fetchBankAccounts();

        const bankAccountAdded = (addedBankAccount) => {
            bankAccounts.value = [...bankAccounts.value, addedBankAccount];
        };

        const bankAccountEdited = (editedBankAccount) => {
            const index = bankAccounts.value
                .findIndex(
                    (bankAccount) => (
                        bankAccount.bankAccountId
                        === editedBankAccount.bankAccountId
                    ),
                );

            bankAccounts.value[index] = editedBankAccount;
        };

        return {
            bankAccounts,
            bankAccountAdded,
            bankAccountEdited,
            banks,
        };
    },
};
</script>
